/* global config, Promise, angular */

'use strict';

angular.module('managerApp').factory('procedureService', function (http, $http, toast, $location) {
    const url = config.apiURL + 'procedures/';

    return {
        get: (by, page = 1, pageSize) => {
            let _url = url, params = [];
            return new Promise((resolve, reject) => {
                if (by) {
                    if (by.type) {
                        params.push('filter[type]=' + by.type);
                    }
                    if (by.id) {
                        _url += by.id;
                    } else { // options only for many results
                        for (let i in by) {
                            if (['id', 'type'].indexOf(i) < 0) {
                                params.push('filter[' + i + ']=' + by[i]);
                            }
                        }
                        if (page > 1) {
                            params.push('page=' + parseInt(page));
                        }
                        if (pageSize) {
                            params.push('pageSize=' + parseInt(pageSize));
                        }
                    }
                }
                if (params.length > 0) {
                    _url += '?' + params.join('&');
                }
                http.get(_url).then((res) => {
                    if (typeof res.data.count !== 'undefined' &&
                            typeof res.data.rows !== 'undefined') {
                        resolve({
                            count: res.data.count,
                            items: res.data.rows
                        });
                    } else {
                        resolve(res.data);
                    }
                }, (err) => {
                    if (err) {
                        if (err.message) {
                            if (err.type !== 'HTTPService') {
                                return reject(err.message);
                            } else {
                                toast.show(err.message, 'error', true);
                            }
                        }
                        reject(err);
                    }
                    reject();
                });
            });
        },
        getCounts: () => {
            let _url = url + 'count';
            return new Promise((resolve, reject) => {
                http.get(_url).then((res) => {
                    if (res.data) {
                        resolve(res.data);
                    }
                }, (err) => {
                    reject(err);
                });
            });
        },
        sendToTMS: (procedureId) => {
            if (!procedureId) {
                return Promise.reject('NoProcedureId');
            }
            let _url = url + procedureId + '/tms';
            return new Promise((resolve, reject) => {
                http.put(_url).then((res) => {
                    resolve(res.data);
                }, (err) => {
                    reject(err);
                });
            });
        },
        handleUnhandle: (procedureId, unhandle = false, isFromList = false) => {
            if (!procedureId) {
                return Promise.reject('NoProcedureId');
            }
            const action = (unhandle ? 'un' : '') + 'handle';
            const _url = url + procedureId + '/' + action;
            return new Promise((resolve, reject) => {
                http.put(_url).then((res) => {
                    const path = res.data.path, procedureType = path.split('/')[0];
                    const pathToGo = 'procedures/' + (unhandle ? procedureType : path);
                    if (!isFromList) {
                        if (res.data.path) {
                            $location.path(pathToGo).search({action: action});
                        }
                    }
                    resolve(res.data);
                }, (err) => {
                    reject(err);
                });
            });
        },
        getVehicleInfos: (vehicleRegistrationNum = '') => {
            let _url = url + 'data/vehicule/', params = [];
            if (vehicleRegistrationNum !== '') {
                //vehicleRegistrationNum = vehicleRegistrationNum.replace(/-|\s/g, '');
                params.push('filter[vehicleRegistrationNum]=' + vehicleRegistrationNum);
            } else {
                return Promise.reject('NoVehicleRegistrationNum');
            }
            if (params.length > 0) {
                _url += '?' + params.join('&');
            }
            return new Promise((resolve, reject) => {
                http.get(_url).then((res) => {
                    if (res.data) {
                        resolve(res.data);
                    }
                }, (err) => {
                    reject(err);
                });
            });
        },
        createShippingLabel: (data) => {
            if (data === null) {
                return Promise.reject('NoData');
            }
            let _url = config.apiURL + 'shipments/label';
            return new Promise((resolve, reject) => {
                http.post(_url, data).then((res) => {
                    console.log(res);
                    resolve(res);
                }, (err) => {
                    reject(err);
                });
            });
        },
        notify: (procedureId = null, notifType = null) => {
            if (procedureId === null || notifType === null) {
                return Promise.reject('NoData');
            }
            let _url = url + procedureId + '/notify/' + notifType;
            return new Promise((resolve, reject) => {
                http.put(_url).then((res) => {
                    resolve();
                }, (err) => {
                    reject(err);
                });
            });
        },
        createDC: (procedureId = null) => {
            if (procedureId === null) {
                return Promise.reject('NoData');
            }
            let _url = url + procedureId + '/create-dc';
            return new Promise((resolve, reject) => {
                http.put(_url).then(() => {
                    resolve();
                }, (err) => {
                    reject(err);
                });
            });
        },
        validate: (procedureId = null) => {
            if (procedureId === null) {
                return Promise.reject('NoData');
            }
            let _url = url + procedureId + '/validate';
            return new Promise((resolve, reject) => {
                http.put(_url).then(() => {
                    resolve();
                }, (err) => {
                    reject(err);
                });
            });
        },
        reject: (procedureId = null, comment = null) => {
            if (procedureId === null || comment === null) {
                return Promise.reject('NoData');
            }
            let _url = url + procedureId + '/reject';
            return new Promise((resolve, reject) => {
                http.put(_url, {
                    comment: comment
                }).then(() => {
                    resolve();
                }, (err) => {
                    reject(err);
                });
            });
        },
        unreject: (procedureId = null) => {
            if (procedureId === null) {
                return Promise.reject('NoData');
            }
            let _url = url + procedureId + '/unreject';
            return new Promise((resolve, reject) => {
                http.put(_url).then(() => {
                    resolve();
                }, (err) => {
                    reject(err);
                });
            });
        },
        archive: (procedureId = null) => {
            if (procedureId === null) {
                return Promise.reject('NoData');
            }
            let _url = url + procedureId + '/archive';
            return new Promise((resolve, reject) => {
                http.put(_url).then(() => {
                    resolve();
                }, (err) => {
                    reject(err);
                });
            });
        },
        createPayment: (procedureId = null, data = null) => {
            if (procedureId === null) {
                return Promise.reject('NoData');
            }
            let _url = url + procedureId + '/create-payment';
            return new Promise((resolve, reject) => {
                http.put(_url, data).then((res) => {
                    if (res.data) {
                        resolve(res.data);
                    }
                }, (err) => {
                    reject(err);
                });
            });
        },
        getPayments: (procedureId = null, itemId = null) => {
            if (procedureId === null || itemId === null) {
                return Promise.reject('NoData');
            }
            let _url = url + procedureId + '/payments/' + itemId;
            return new Promise((resolve, reject) => {
                http.get(_url).then((res) => {
                    if (res.data) {
                        resolve(res.data);
                    }
                }, (err) => {
                    reject(err);
                });
            });
        },
        getHistory: (procedureId = null) => {
            if (procedureId === null) {
                return Promise.reject('NoData');
            }
            let _url = url + procedureId + '/history';
            return new Promise((resolve, reject) => {
                http.get(_url).then((res) => {
                    if (res.data) {
                        resolve(res.data);
                    }
                }, (err) => {
                    reject(err);
                });
            });
        },
        // itemType : person, address, vehicle
        updateItem: (procedureId = null, itemType = null, itemId = null, item = {}) => {
            if (procedureId === null || itemType === null || itemId === null || item === {}) {
                return Promise.reject('NoData');
            }
            let _url = url + procedureId + '/update/' + itemType + '/' + itemId;
            return new Promise((resolve, reject) => {
                http.put(_url, item).then((res) => {
                    resolve(res && res.data ? res.data : {});
                }, (err) => {
                    reject(err);
                });
            });
        },
        getDocuments: (procedureId = null, procedureType = null) => {
            if (procedureId === null) {
                return Promise.reject('NoData');
            }
            let _url = url + procedureId + '/documents/';
            if (typeof procedureType === 'string') {
                _url += procedureType;
            }
            return new Promise((resolve, reject) => {
                http.get(_url).then((res) => {
                    if (res.data) {
                        resolve(res.data);
                    }
                }, (err) => {
                    reject(err);
                });
            });
        },
        addDocument: (procedureId = null, document = null) => {
            if (procedureId === null || document === null) {
                return Promise.reject('NoData');
            }
            let _url = url + procedureId + '/documents/add';
            return new Promise((resolve, reject) => {
                http.put(_url, document).then((res) => {
                    if (res.data) {
                        resolve(res.data);
                    }
                }, (err) => {
                    reject(err);
                });
            });
        },
        removeExtraDocument: (procedureId = null, document = null) => {
            if (procedureId === null || document === null) {
                return Promise.reject('NoData');
            }
            let _url = url + procedureId + '/documents/remove';
            return new Promise((resolve, reject) => {
                http.put(_url, document).then((res) => {
                    if (res.data) {
                        resolve(res.data);
                    }
                }, (err) => {
                    reject(err);
                });
            });
        },
        uploadDocument: (procedure = null, document = null, documentType = null) => {
            if (procedure === null || document === null || documentType === null) {
                return Promise.reject('NoData');
            }
            let _url = url + procedure._id + '/upload/' + documentType;
            return new Promise((resolve, reject) => {
                http.put(_url, document, {
                    headers: {'Content-Type': undefined}
                }).then((res) => {
                    if (res.data) {
                        resolve(res.data);
                    }
                }, (err) => {
                    reject(err);
                });
            });
        },
        generateDocumentsToSign: (procedureId = null, procedureType = null, clientId = null) => {
            if (procedureId === null || procedureType === null || clientId === null) {
                return Promise.reject('NoData');
            }
            let _url = url + procedureId + '/sign/' + procedureType + '/' + clientId;
            return new Promise((resolve, reject) => {
                http.get(_url).then((res) => {
                    if (res.data) {
                        resolve(res.data);
                    }
                }, (err) => {
                    reject(err);
                });
            });
        },
        update: (procedureId = null, data = null) => {
            if (procedureId === null || data === null) {
                return Promise.reject('NoData');
            }
            let _url = url + procedureId;
            return new Promise((resolve, reject) => {
                http.put(_url, data).then(() => {
                    resolve();
                }, (err) => {
                    reject(err);
                });
            });
        },
        comment: (procedureId, comment) => {
            if (!procedureId || !comment || typeof comment.comment !== 'string') {
                return Promise.reject('NoData');
            }
            let _url = url + procedureId + '/comments';
            return new Promise(function (resolve, reject) {
                http.put(_url, comment).then(function (res) {
                    resolve(res.data);
                }).catch(function (err) {
                    reject(err);
                });
            });
        },
        getComments: (procedureId = null) => {
            if (procedureId === null) {
                return Promise.reject('NoData');
            }
            let _url = url + procedureId + '/comments';
            return new Promise((resolve, reject) => {
                http.get(_url).then((res) => {
                    if (res.data) {
                        resolve(res.data);
                    }
                }, (err) => {
                    reject(err);
                });
            });
        },
        deleteComment: (procedureId = null, commentId = null) => {
            if (procedureId === null || commentId === null) {
                return Promise.reject('NoData');
            }
            let _url = url + procedureId + '/comments/' + commentId;
            return new Promise((resolve, reject) => {
                http.delete(_url).then((res) => {
                    resolve();
                }, (err) => {
                    reject(err);
                });
            });
        },
        calculateTaxes: (datataxes) => {
            var _url = config.websiteURL + '/api/taxes';
            return new Promise(function (resolve, reject) {
                http.post(_url, datataxes).then(function (res) {
                    resolve(res.data);
                }).catch(function (err) {
                    console.log("ERROR:", err);
                    reject(err);
                });
            });
        },
        addNotify: (procedureId, notify) => {
            if (!procedureId) {
                return Promise.reject();
            }
            let _url = url + procedureId + '/notify';
            return new Promise(function (resolve, reject) {
                http.post(_url, procedureId, notify).then(function (res) {
                    resolve(res.data);
                }).catch(function (err) {
                    reject(err);
                });
            });
        },
        createRefund: (procedureId, refund) => {
            if (!procedureId || !refund) {
                return Promise.reject('NoData');
            }
            let _url = url + procedureId + '/refunds';
            return new Promise(function (resolve, reject) {
                http.put(_url, refund).then(function (res) {
                    resolve(res.data);
                }).catch(function (err) {
                    reject(err);
                });
            });
        },
        cancelRefund: (procedureId, refundId) => {
            if (!procedureId || !refundId) {
                return Promise.reject('NoData');
            }
            let _url = url + procedureId + '/refunds/' + refundId + '/cancel';
            return new Promise(function (resolve, reject) {
                http.put(_url).then(function (res) {
                    resolve(res.data);
                }).catch(function (err) {
                    reject(err);
                });
            });
        },
        getSituationAdministrative: (procedureId = null, type = 'dsv', data = null) => {
            if (!procedureId) {
                return Promise.reject();
            }
            let _url = url + procedureId + '/situation-administrative/?filter[type]=' + type;
            return new Promise(function (resolve, reject) {
                http.put(_url, data).then(function (res) {
                    resolve(res.data);
                }).catch(function (err) {
                    reject(err);
                });
            });
        },
        getFicheIdentificationVehicule: (procedureId = null, data = null) => {
            if (!procedureId) {
                return Promise.reject();
            }
            let _url = url + procedureId + '/fiche-identification';
            return new Promise(function (resolve, reject) {
                http.put(_url, data).then(function (res) {
                    resolve(res.data);
                }).catch(function (err) {
                    reject(err);
                });
            });
        },
        getFIV: (data = null) => {
            if (!data) {
                return Promise.reject();
            }
            let _url = url + 'fiv';
            return new Promise(function (resolve, reject) {
                http.put(_url, data).then(function (res) {
                    resolve(res.data);
                }).catch(function (err) {
                    reject(err);
                });
            });
        },
        getVehicleTypes: () => {
            let _url = url + 'data/vehicules';
            return new Promise((resolve, reject) => {
                http.get(_url).then((res) => {
                    if (res.data) {
                        resolve(res.data);
                    }
                }, (err) => {
                    reject(err);
                });
            });
        },
        getFuelTypes: () => {
            let _url = url + 'data/energies';
            return new Promise((resolve, reject) => {
                http.get(_url).then((res) => {
                    if (res.data) {
                        resolve(res.data);
                    }
                }, (err) => {
                    reject(err);
                });
            });
        },
        sendSMS: (procedureId = null, data = null) => {
            if (!procedureId || !data) {
                return Promise.reject();
            }
            let _url = url + procedureId + '/sms';
            return new Promise(function (resolve, reject) {
                http.put(_url, data).then(function (res) {
                    resolve(res.data);
                }).catch(function (err) {
                    reject(err);
                });
            });
        },
        savePayment: (procedureId = null, paymentData = null) => {
            if (!procedureId || !paymentData) {
                return Promise.reject();
            }
            let _url = url + procedureId + '/' + 'save-payment';
            return new Promise(function (resolve, reject) {
                http.put(_url, paymentData).then(function (res) {
                    resolve(res.data);
                }).catch(function (err) {
                    reject(err);
                });
            });
        },
        attachToClient: (procedureId = null, data = null) => {
            if (!procedureId || !data) {
                return Promise.reject();
            }
            let _url = url + procedureId + '/' + 'attach-to-client';
            return new Promise(function (resolve, reject) {
                http.put(_url, data).then(function (res) {
                    resolve(res.data);
                }).catch(function (err) {
                    reject(err);
                });
            });
        },
        declareFraud: (procedureId, fraud) => {
            if (!procedureId || !fraud) {
                return Promise.reject('NoData');
            }
            let _url = url + procedureId + '/frauds';
            return new Promise(function (resolve, reject) {
                http.put(_url, fraud).then(function (res) {
                    resolve(res.data);
                }).catch(function (err) {
                    reject(err);
                });
            });
        },
        sendTerminalMessage: (procedureId, data) => {
            if (!procedureId || !data) {
                return Promise.reject('NoData');
            }
            let _url = url + procedureId + '/send-terminal-message';
            return new Promise(function (resolve, reject) {
                http.put(_url, data).then(function (res) {
                    resolve(res.data);
                }).catch(function (err) {
                    reject(err);
                });
            });
        },
        addAssistance: (procedureId, assistance) => {
            if (!procedureId || !assistance) {
                return Promise.reject('NoData');
            }
            let _url = url + procedureId + '/assistances';
            return new Promise(function (resolve, reject) {
                http.put(_url, assistance).then(function (res) {
                    resolve(res.data);
                }).catch(function (err) {
                    reject(err);
                });
            });
        },
        getAssistances: (procedureId = null) => {
            if (procedureId === null) {
                return Promise.reject('NoData');
            }
            let _url = url + procedureId + '/assistances';
            return new Promise((resolve, reject) => {
                http.get(_url).then((res) => {
                    if (res.data) {
                        resolve(res.data);
                    }
                }, (err) => {
                    reject(err);
                });
            });
        },
        exportDataAndDocs: (procedureId = null) => {
            if (procedureId === null) {
                return Promise.reject('NoData');
            }
            let _url = url + procedureId + '/export';
            let _options = {
                responseType: 'arraybuffer'
            };
            return new Promise((resolve, reject) => {
                http.get(_url, _options).then((res) => {
                    resolve(res);
                }, (res) => {
                    try {
                        let enc = new TextDecoder("utf-8");
                        let uArray = new Uint8Array(res);
                        let err = JSON.parse(enc.decode(uArray));
                        reject(err);
                    } catch (e) {
                        reject();
                    }
                });
            });
        },
        getDocumentsUploadLink: (procedureId = null) => {
            if (procedureId === null) {
                return Promise.reject('NoData');
            }
            let _url = url + procedureId + '/documents/upload-link';
            return new Promise((resolve, reject) => {
                http.get(_url).then((res) => {
                    if (res.data) {
                        resolve(res.data);
                    }
                }, (err) => {
                    reject(err);
                });
            });
        },
        documentsBulkCheck: (procedureId = null, documents = null) => {
            if (!procedureId || !documents) {
                return Promise.reject('NoData');
            }
            let _url = url + procedureId + '/documents/bulk-check';
            return new Promise(function (resolve, reject) {
                http.put(_url, documents).then(function (res) {
                    resolve(res.data);
                }).catch(function (err) {
                    reject(err);
                });
            });
        },
        getRefund: (procedureId = null) => {
            if (procedureId === null) {
                return Promise.reject('NoData');
            }
            let _url = url + procedureId + '/refund';
            return new Promise((resolve, reject) => {
                http.get(_url).then((res) => {
                    if (res.data) {
                        resolve(res.data);
                    }
                }, (err) => {
                    reject(err);
                });
            });
        },
        getReceiptDownloadLink: (procedureId = null) => {
            if (procedureId === null) {
                return Promise.reject('NoData');
            }
            let _url = url + procedureId + '/documents/recepit-download-link';
            return new Promise((resolve, reject) => {
                http.get(_url).then((res) => {
                    if (res.data) {
                        resolve(res.data);
                    }
                }, (err) => {
                    reject(err);
                });
            });
        },
        quickValidate: (procedureId = null, data) => {
            if (procedureId === null) {
                return Promise.reject('NoData');
            }
            let _url = url + procedureId + '/quick-validate';
            return new Promise((resolve, reject) => {
                http.put(_url, data).then(() => {
                    resolve();
                }, (err) => {
                    reject(err);
                });
            });
        },
        getNeverHandledProcedures: () => {
            let _url = url + 'never-handled';
            return new Promise((resolve, reject) => {
                http.get(_url).then((res) => {
                    if (res.data) {
                        resolve(res.data);
                    }
                }, (err) => {
                    reject(err);
                });
            });
        },
        getChatMessages: (procedureId = null) => {
            if (procedureId === null) {
                return Promise.reject('NoData');
            }
            let _url = url + procedureId + '/chat';
            return new Promise((resolve, reject) => {
                http.get(_url).then((res) => {
                    if (res.data) {
                        resolve(res.data);
                    }
                }, (err) => {
                    reject(err);
                });
            });
        },
        postChatMessage: (procedureId = null, data) => {
            if (procedureId === null) {
                return Promise.reject('NoData');
            }
            let _url = url + procedureId + '/chat/post';
            return new Promise((resolve, reject) => {
                http.put(_url, data).then(() => {
                    resolve();
                }, (err) => {
                    reject(err);
                });
            });
        },
        setChatMessagesAsRead: (procedureId = null) => {
            if (procedureId === null) {
                return Promise.reject('NoData');
            }
            let _url = url + procedureId + '/chat/read';
            return new Promise((resolve, reject) => {
                http.put(_url).then(() => {
                    resolve();
                }, (err) => {
                    reject(err);
                });
            });
        },
        updateTaxes: (procedureId = null) => {
            if (procedureId === null) {
                return Promise.reject('NoData');
            }
            let _url = url + procedureId + '/taxes';
            return new Promise((resolve, reject) => {
                http.put(_url).then((res) => {
                    if (res.data) {
                        resolve(res.data);
                    }
                    resolve(res);
                }, (err) => {
                    reject(err);
                });
            });
        }
    };
});